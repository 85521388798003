@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@700;800;900&display=swap');

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter",'Red Hat Display',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
